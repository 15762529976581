












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'StatisticCard',
})
export default class StatisticCard extends Vue {
  @Prop({ default: 0 }) value?: number | string;
  @Prop({ default: '' }) title?: string;
}
