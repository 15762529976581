






import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { Tag } from '@/core/tags/models/Tag';
import { TagType } from '@/core/tags/enums/TagType';
import { findTagsUseCase } from '@/core/tags/usecases/findTagsUseCase';
import { playoutFormat } from '@/ui/util/numberFormat';
import { roundCorners } from '@/ui/util/chartRoundedCorners';
import Highcharts from 'highcharts';
import { ValueByMonth, ValuesPerMonthAndZone } from '@/core/statistics/models/Statistics';
import { ColumnSeriesOptions } from '@/core/statistics/models/CustomHighcharts';
import { STATISTIC } from '@/ui/constant/statistic.const';

@Component({
  name: 'StatisticCharts',
})
export default class StatisticCharts extends Vue {
  @Prop() statistics!: ValuesPerMonthAndZone[];
  @Prop({ default: true }) inverted?: boolean;
  @Prop({ default: true }) stackLabels?: boolean;
  private xAxisValues = [] as string[];
  private seriesData = [] as Highcharts.SeriesOptionsType[];
  private zoneColors = ['#e0e4f2', '#bdc7d7', '#a6b1c3', '#8390a5', '#5d6777'] as string[];
  private zones: Tag[] = [];
  private height = STATISTIC.HIGHCHARTS_DEFAULT_HEIGHT;

  async created() {
    await this.fetchTags('');
    if (this.statistics?.length > 0) this.setStatisticsData();
  }

  @Watch('statistics')
  onUpdate() {
    this.setStatisticsData();
  }

  get currentUser() {
    return this.$store.state.userStore.currentUser;
  }

  get customOptions() {
    const chartConfig: Highcharts.Options = {
      chart: {
        type: 'column',
        inverted: this.inverted,
        height: this.height,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      colors: ['#2b2b2b'],
      xAxis: {
        categories: this.xAxisValues,
        lineWidth: 0,
      },
      yAxis: {
        labels: {
          formatter(): string {
            let val = this.value;
            if (typeof val == 'string') {
              val = parseInt(val);
            }
            return playoutFormat(val);
          },
        },
        stackLabels: {
          style: {
            color: 'black',
            fontWeight: 'normal',
          },
          enabled: this.stackLabels,
          align: 'left',
          verticalAlign: 'bottom',
          y: 12,
          formatter(): string {
            return playoutFormat(this.total) + ' Playouts';
          },
        },
        gridLineWidth: 0,
        title: {
          text: null,
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        column: {
          pointWidth: 10,
        },
        series: {
          stacking: 'normal',
          borderColor: '#2b2b2b',
          borderWidth: 1,
          states: {
            hover: {
              brightness: 0,
              borderColor: 'white',
            },
          },
        },
      },
      tooltip: {
        shared: true,
        borderColor: 'white',
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        outside: true,
        shadow: { color: 'rgba(20, 40, 73, 0.1)' },
        style: { fontFamily: 'Gilroy' },
        padding: 16,
        useHTML: true,
        shape: 'square',
        formatter: function () {
          const allPlayouts = this.points?.reduce(function (total, point) {
            return total + point.y;
          }, 0);
          return this.points?.reduce(function (s, point) {
            return (
              s +
              '<br/>' +
              ` <div style="display: flex;justify-content: space-between;
              align-items: center; font-size: 12px;">
              <div style="display: flex; align-items: center;">
            <span style="height:8px; width:8px; margin-top: -1px; margin-right: 4px; background-color: ${
              (point.series.userOptions as ColumnSeriesOptions).states.hover.color
            }; display: inline-block; "></span>
        <div class="text">${point.series.name}:&nbsp;</div>
      </div>
      <div> <strong>${playoutFormat(point.y)}</strong></div>
    </div>`
            );
          }, 'Playouts ' +
            this.x +
            '<br/>' +
            `<span style="font-size: 20px;font-weight: bold;">${playoutFormat(allPlayouts)}<br/></span>`);
        },
      },
      series: this.seriesData,
    };
    roundCorners();
    return chartConfig;
  }

  async fetchTags(search: string) {
    this.zones = await findTagsUseCase({ search, type: TagType.ZONE }, this.currentUser);
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  setStatisticsData() {
    // get a sorted list of all months for which we have some playout data
    this.xAxisValues = this.getSortedMonthsWithData();
    // map statistic measurements to highcharts data object
    this.seriesData = this.getChartsDataFromStatistics();
    // set custom height of the highcharts object if inverted
    if (this.inverted) {
      this.height =
        this.xAxisValues.length > 0
          ? this.xAxisValues.length * STATISTIC.HIGHCHARTS_DEFAULT_BAR_HEIGHT + STATISTIC.HIGHCHARTS_ADDITIONAL_SPACE
          : 0;
    }
    // assign a color value to each zone data on each month and rounded corners for top and bottom
    let colorIndex = 0;
    this.seriesData.forEach((datapoint: any, index: number) => {
      const numColors = this.zoneColors.length;
      if (index === 0) {
        datapoint.borderRadiusTopLeft = '50%';
        datapoint.borderRadiusTopRight = '50%';
      }
      if (index === this.seriesData?.length - 1) {
        datapoint.borderRadiusBottomLeft = '50%';
        datapoint.borderRadiusBottomRight = '50%';
        datapoint.spaceBottom = 1;
      }
      datapoint.states.hover.color = this.zoneColors[colorIndex % numColors];
      colorIndex += 1;
    });
  }

  comparePlayoutData(measurement1: ValueByMonth, measurement2: ValueByMonth) {
    if (measurement1.month < measurement2.month) {
      return -1;
    }
    if (measurement1.month > measurement2.month) {
      return 1;
    } else return 0;
  }

  getSortedMonthsWithData() {
    const months = [] as string[];
    this.statistics?.forEach((item: ValuesPerMonthAndZone) => {
      item.value.forEach((entry: ValueByMonth) => {
        if (months.indexOf(entry.month) == -1) {
          months.push(entry.month);
        }
      });
    });
    // sort months
    return months.sort();
  }

  getChartsDataFromStatistics(): Highcharts.SeriesOptionsType[] {
    return [...this.statistics]
      .sort((a, b) => (a.value[0]?.value > b.value[0]?.value ? -1 : a.value[0]?.value < b.value[0]?.value ? 1 : 0))
      .map((measurement: ValuesPerMonthAndZone) => {
        // add 0 vaues to month where there is no entry
        this.xAxisValues.forEach((listedMonth: string) => {
          const monthIdx = measurement.value.findIndex((item: ValueByMonth) => {
            return item.month === listedMonth;
          });
          if (monthIdx === -1) {
            measurement.value.push({ month: listedMonth, value: 0 });
          }
        });
        // sort measurements by date
        measurement.value.sort(this.comparePlayoutData);
        return {
          name: this.$tagValue(measurement.zone),
          data: measurement.value.map((month: ValueByMonth) => month.value) as Highcharts.XrangePointOptionsObject[],
          borderRadiusTopRight: '',
          borderRadiusTopLeft: '',
          borderRadiusBottomLeft: '',
          borderRadiusBottomRight: '',
          spaceBottom: 0,
          bottomMargin: 0,
          states: {
            hover: {
              color: '',
            },
          },
          type: 'column',
        };
      });
  }
}
