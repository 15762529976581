
































import { Component, Prop, Vue } from 'vue-property-decorator';
import DateFilter from '@/ui/components/molecules/filter/DateFilter.vue';
import ChannelFilter from '@/ui/components/molecules/filter/ChannelFilter.vue';
import getLogger from '@/shared/logger/logger';
import type { Tag } from '@/core/tags/models/Tag';
import { exportToSpreadsheet } from '@/ui/util/xlsx';
import { BaseStatistic, CampaignStatistic, GeneralStatistic } from '@/core/statistics/models/Statistics';
import { STATISTIC } from '@/ui/constant/statistic.const';
import { Loader } from '@/ui/util/decorators/loader';
import { CampaignStoreGetters } from '@/ui/stores/CampaignStore';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = getLogger('CampaignStatisticsFilter');

@Component({
  name: 'CampaignStatisticsFilter',
  components: { DateFilter, ChannelFilter },
})
export default class CampaignStatisticsFilter extends Vue {
  @Prop({ default: true }) hasRemovableDatesFilter?: boolean;
  @Prop() statisticView!: STATISTIC;
  @Prop() zones: Tag[] | undefined;
  @Prop() selectedZones!: Tag[];
  @Prop() selectedDates!: string[];
  private isStatistics = true;

  get currentUser() {
    return this.$store.state.userStore.currentUser;
  }

  get generalStatistic(): GeneralStatistic | undefined {
    return this.$store.state.statisticStore.generalStatistic;
  }

  get campaignStatistic(): BaseStatistic | undefined {
    return this.$store.state.statisticStore.campaignStatistic;
  }

  @Loader
  exportStatistics() {
    if (this.statisticView === STATISTIC.GENERAL) {
      exportToSpreadsheet(this.generalStatistic, this.selectedDates, STATISTIC.GENERAL);
    } else {
      const selectedCampaign = this.$store.getters[CampaignStoreGetters.GET_SELECTED_CAMPAIGN];
      const campaignStatistic = this.campaignStatistic as CampaignStatistic;
      campaignStatistic.campaignTitle = selectedCampaign.title;
      campaignStatistic.campaignStart = selectedCampaign.startDate;
      campaignStatistic.campaignEnd = selectedCampaign.endDate;

      exportToSpreadsheet(campaignStatistic, this.selectedDates, STATISTIC.CAMPAIGN);
    }
  }

  updateZones(zones: Tag[]) {
    this.updateParent(zones, this.selectedDates);
  }

  updateDates(dates: string[]) {
    this.updateParent(this.selectedZones, dates);
  }

  updateParent(zones: Tag[], dates: string[]) {
    this.$emit('updated', zones, dates);
  }

  resetAllFilters() {
    this.updateParent([], []);
  }
}
