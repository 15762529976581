import { CampaignStatistic, GeneralStatistic, ValuesPerMonthAndZone } from '@/core/statistics/models/Statistics';
import { STATISTIC } from '@/ui/constant/statistic.const';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import i18n from '@/i18n';

const fileName = `Media_Cloud_Export-${new Date().toLocaleDateString('de-CH').replaceAll('.', '_')}`;
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const SHEET_NAMES = {
  OVERVIEW: 'Overview',
};

const getSumOfValuesPerDate = (dates: string[], data: ValuesPerMonthAndZone[]) => {
  return dates.map((date) => {
    const playoutValuesOfDate = data.map((zoneData) => {
      return zoneData.value.find((v) => v.month === date)?.value || 0;
    });
    return playoutValuesOfDate.reduce((acc, playoutValue) => {
      return acc + playoutValue;
    }, 0);
  });
};

const getDateStringFromString = (date: string) => {
  return new Date(date).toLocaleDateString('de-CH');
};

const getExportDate = () => {
  return new Date().toLocaleDateString('de-CH');
};

const getDateRangeString = (dates: string[]) => {
  return dates.map((date) => date.substring(0, 10).replaceAll('-', '.')).join(' - ');
};

const createOverview = (data: GeneralStatistic, filteredDates: string[]) => {
  const allDates = data.playoutsPerMonthByZone[0].value.map((value) => value.month.toString());

  const totalPlayoutsPerMonth = getSumOfValuesPerDate(allDates, data.playoutsPerMonthByZone);
  const totalMediaValuePerMonth = getSumOfValuesPerDate(allDates, data.mediaValuePerMonthByZone);

  return [
    [i18n.i18next.t('campaign.campaign-statistics.exportTotalStatisticsTitle')],
    [
      i18n.i18next.t('campaign.campaign-statistics.exportFilterDate', {
        dateRange: getDateRangeString([
          getDateStringFromString(filteredDates[0]),
          getDateStringFromString(filteredDates[1]),
        ]),
      }),
    ],
    [i18n.i18next.t('campaign.campaign-statistics.exportCreatedOn', { date: getExportDate() })],
    [],
    [
      i18n.i18next.t('campaign.campaign-statistics.total'),
      i18n.i18next.t('campaign.campaign-statistics.campaignCount'),
      i18n.i18next.t('campaign.campaign-statistics.playouts'),
      i18n.i18next.t('campaign.campaign-statistics.mediaValue'),
    ],
    ['', data.campaignCount, data.playouts, data.mediaValue],
    [],
    [i18n.i18next.t('campaign.campaign-statistics.topCampaignsTitle')],
    ...data.topCampaigns.map((c) => [c.title, c.playouts]),
    [],
    [i18n.i18next.t('campaign.campaign-statistics.playouts')],
    [i18n.i18next.t('campaign.campaign-statistics.exportZone'), ...allDates],
    ...data.playoutsPerMonthByZone.map((d) => [d.zone.value['de-CH'], ...d.value.map((s) => s.value)]),
    [i18n.i18next.t('campaign.campaign-statistics.total'), ...totalPlayoutsPerMonth],
    [],
    [i18n.i18next.t('campaign.campaign-statistics.mediaValue')],
    [i18n.i18next.t('campaign.campaign-statistics.exportZone'), ...allDates],
    ...data.mediaValuePerMonthByZone.map((d) => {
      return [d.zone.value['de-CH'], ...allDates.map((date) => d.value.filter((v) => v.month === date)[0]?.value || 0)];
    }),
    [i18n.i18next.t('campaign.campaign-statistics.total'), ...totalMediaValuePerMonth],
  ];
};

const createCampaign = (data: CampaignStatistic, filteredDates: string[]) => {
  const campaignDuration = getDateRangeString([
    getDateStringFromString(data.campaignStart),
    getDateStringFromString(data.campaignEnd),
  ]);
  let filterOrDurationDates: string;

  if (filteredDates?.length > 0) {
    filterOrDurationDates = filteredDates.join(' - ');
  } else {
    filterOrDurationDates = campaignDuration;
  }

  const allDates = data.playoutsPerMonthByZone[0].value.map((value) => value.month.toString());

  const totalPlayoutsPerMonth = getSumOfValuesPerDate(allDates, data.playoutsPerMonthByZone);

  return [
    [data.campaignTitle],
    [
      i18n.i18next.t('campaign.campaign-statistics.exportCampaignDuration', {
        dateRange: campaignDuration,
      }),
    ],
    [
      i18n.i18next.t('campaign.campaign-statistics.exportFilterDate', {
        dateRange: filterOrDurationDates,
      }),
    ],
    [i18n.i18next.t('campaign.campaign-statistics.exportCreatedOn', { date: getExportDate() })],
    [],
    [
      i18n.i18next.t('campaign.campaign-statistics.total'),
      i18n.i18next.t('campaign.campaign-statistics.exportZones'),
      i18n.i18next.t('campaign.campaign-statistics.playouts'),
      i18n.i18next.t('campaign.campaign-statistics.mediaValue'),
    ],
    ['', data.zoneCount, data.playouts, data.mediaValue],
    [],
    [i18n.i18next.t('campaign.campaign-statistics.playouts')],
    [i18n.i18next.t('campaign.campaign-statistics.exportZone'), ...allDates],
    ...data.playoutsPerMonthByZone.map((d) => [d.zone.value['de-CH'], ...d.value.map((s) => s.value)]),
    [i18n.i18next.t('campaign.campaign-statistics.total'), ...totalPlayoutsPerMonth],
  ];
};

export const exportToSpreadsheet = (
  data: GeneralStatistic | CampaignStatistic | undefined,
  filteredDates: string[],
  type: STATISTIC,
) => {
  if (!data) {
    return;
  }

  const workbook = XLSX.utils.book_new();

  if (type === STATISTIC.GENERAL) {
    const overviewStructure = createOverview(data as GeneralStatistic, filteredDates);
    const overviewSheet = XLSX.utils.aoa_to_sheet(overviewStructure);
    XLSX.utils.book_append_sheet(workbook, overviewSheet, SHEET_NAMES.OVERVIEW);
  } else {
    const campaignStructure = createCampaign(data as CampaignStatistic, filteredDates);
    const campaignSheet = XLSX.utils.aoa_to_sheet(campaignStructure);
    XLSX.utils.book_append_sheet(workbook, campaignSheet, SHEET_NAMES.OVERVIEW);
  }
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const fileData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(fileData, fileName + fileExtension);
};
